.card-container {
  overflow: hidden;
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.compact-card {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  height: 9rem;
  max-width: 15rem;
  border-radius: 1rem;
  color: white;
  position: relative;
  padding: 1.25rem;
  cursor: pointer;
}

.compact-card:hover {
  box-shadow: none!important;
}

.radial-bar {
  flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CircularProgressbar{
  width: 6rem!important;
  overflow: visible!important;
}

.CircularProgressbar-path {
  stroke: white!important;
  stroke-width: 0.6875rem!important;
  filter: drop-shadow(0.0625rem 0.125rem 0.1875rem white)!important;
}

.CircularProgressbar-trail {
  display: none!important;
}

.CircularProgressbar-text {
  fill: white!important;
}

.radial-bar>span {
  white-space: nowrap;
}

.radial-bar>span:nth-child(2) {
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 1rem;
}

.radial-bar>span:nth-child(3) {
  font-size: 0.75rem;
}

.compact-details {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 1rem;
  align-items: flex-end;
  margin-left: 0.75rem;
}

.compact-details>span {
  text-align: right;
  display: inline-block;
}

.compact-details>span:nth-child(1) {
  font-size: 1rem;
  font-weight: bold;
}

.compact-details>span:nth-child(2) {
  font-size: 0.75rem;
  font-weight: bold;
}

.compact-details>span:nth-child(3) {
  font-size: 0.875rem;
  font-weight: bold;
  white-space: nowrap;
}

.compact-details>span:nth-child(4) {
  font-size: 0.875rem;
  font-weight: bold;
  white-space: nowrap;
}

.expanded-card {
  position: absolute;
  width: 67.5%;
  height: 70vh;
  z-index: 99;
  left: 21rem;
  top: 15%;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  transition: all 1000ms ease;
  overflow-y: scroll;
  scrollbar-width: none;
  box-shadow: 0px 0.5rem 1rem 0px var(--dashboardDarkest);
  background: var(--dashboardDark);
}

.expanded-card::-webkit-scrollbar {
  display: none;
}

.expanded-card-close {
  align-self: flex-end;
  cursor: pointer;
  color: white;
}

.expanded-card>span {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 0px 0px 0.125rem white;
}

.expanded-card-chart-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.expanded-card-chart-time-series {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.expanded-card-summary-container {
  background-color: var(--dashboardMidDark);
  border-radius: 1rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.375);
  z-index: 50;
}

@media screen and (max-width: 1600px) {
  .CircularProgressbar{
    width: 5rem!important;
    overflow: visible!important;
  }
}

@media screen and (max-width: 1366px) {
  .compact-card {
    padding: 1rem;
    height: 8rem;
  }

  .CircularProgressbar {
    width: 4.5rem!important;
  }

  .radial-bar>span:nth-child(2),
  .radial-bar>span:nth-child(3) {
    font-size: 0.7rem;
  }

  .compact-details {
    grid-gap: 0.5rem;
  }

  .compact-details>span:nth-child(1) {
    font-size: 0.9rem;
  }

  .compact-details>span:nth-child(2) {
    font-size: 0.7rem;
  }

  .compact-details>span:nth-child(3),
  .compact-details>span:nth-child(4) {
    font-size: 0.8rem;
  }
}
