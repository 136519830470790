.data-request-background {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;
    align-content: center;
    flex: 1;
    flex-grow: 1;
    background: linear-gradient(to right, #1d243c, #2a314e);
    padding: 1.5rem;
    min-height: 100vh;
    min-width: 100vw;
}

.data-request-header {
    display: flex;
    justify-content: left;
}

.data-request-container {
    width: 65%;
    max-width: 65%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    background-color: #5a5f7a;
    color: #fff;
    font-family: Arial, sans-serif;
}

.back-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.back-icon {
    width: 48px;
    height: 48px;
}

.data-request-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    flex-grow: 1;
}

.data-request-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.data-request-error {
    margin-top: 5px;
    color: #DC143C;
    font-size: 14px;
    font-weight: bold;
}

.data-request-section {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.data-request-label {
    font-size: 18px;
    flex: 0 0 140px;
    text-align: left;
    margin-right: 10px;
    display: flex;
    flex-grow: 1;
}

.data-request-input {
    flex-grow: 1;
    width: calc(100% - 40px);
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #478dcd;
    outline: none;
    font-size: 14px;
}

.data-request-button {
    width: 100%;
    padding: 12px;
    margin-top: 15px;
    font-size: 16px;
    border-radius: 8px;
}

.data-request-button:hover {
    background-color: #47cd87;
}

.data-request-char-count {
    margin-top: 1rem;
    font-size: 14px;
    text-align: right;
}