:root {
  --primary: #478dcd;
  --primaryDark: #1a71c1;
  --primaryLight: #83b2dd;
  --green: #71c11a;
  --yellow: #f0e14a;
  --steel: 	#d4d4d4;
  --dashboardDarkest: #1d243c;
  --dashboardDark: #2a314e;
  --dashboardMidDark: #3f455f;
  --dashboardMid: #5a5f7a;
  --dashboardLight: #898da5;
  --dashboardLightest: #e7e9fb;
  --spaceGrayDarkest: #343d46;
  --spaceGrayDark: #4f5b66;
  --spaceGrayMid: #65737e;
  --spaceGrayLight: #a7adba;
  --spaceGrayLightest: #c0c5ce;
  --hyperlinkBlue: #0ba6ff;

  --happiness: #ffdd57;
  --motivation: #ff6f61;
  --stress: #db0000;
  --optimism: #ffc107;
  --self-awareness: #007bff;
  --self-doubt: #adb5bd;
  --resilience: #28a745;
  --inspiration: #9c27b0;
  --burnout: #AA4203;
  --flow-state: #00acc1;
  --satisfaction: #f4511e;
  --efficiency: #33b679;
  --time-management: #f09300;
  --discipline: #fdd835;
  --empathy: #00bcd4;
  --joy: #5e35b1;
  --personal-development: #005A9B;
  --growth: #c0ca33;
  --focus: #e53935;
  --gratitude: #fb8c00;
  --insecurity: #8e24aa;
  --contentment: #1e88e5;
  --determination: #d81b60;
  --organization: #039be5;
  --confidence: #c62828;
  --hopefulness: #43a047;
  --achievement: #2e7d32;
  --learning: #7b1fa2;
  --goal-setting: #00897b;
  --fulfillment: #e65100;
  --adaptability: #3949ab;
  --empowerment: #fbc02d;
  --anxiety: #692544;
  --love: #63469d;
  --mental-health: #478dcd;
  --mental-health-light: #5998d2;
  --mental-health-dark: #3f7eb8;
  --productivity: #47cd87;
  --productivity-light: #59d293;
  --productivity-dark: #3fb879;
  --personal-growth: #8747cd;
  --personal-growth-light: #9359d2;
  --personal-growth-dark: #793fb8;
  --overall: #40bf00;
  --wellbeing: #40bf00;
  --wellbeing-dark: #39ab00;
  --wellbeing-light: #53c519;
  /*   --overall: #32cb4b;
    --wellbeing: #32cb4b;
    --wellbeing-dark: #19c535;
    --wellbeing-light: #4cd262; */
  --positive: #39ff14;
  --negative: #ff3131;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 0; /* Reduces bottom margin to bring form closer */
}

h3, h4, h5, h6 {
  margin: 0; /* Removes default margin */
  padding: 5px 0; /* Provides a little padding for better readability */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.signup-form {
  display: flex;
  flex-direction: column;
  gap: 10px; /* adds space between the email input and the button */
  width: 80%; /* adjusts width of the form */
  max-width: 400px; /* limits maximum width */
  margin-top: 20px; /* Reduces space above the form */
}

.email-input, .signup-button {
  padding: 10px;
  border-radius: 20px; /* gives rounded corners */
  border: 1px solid #ccc; /* adds a light border */
  width: calc(100% - 22px); /* ensures full width taking padding and border into account */
  box-sizing: border-box; /* includes padding and border in the width */
}

.signup-button {
  background-color: #007bff; /* example blue background */
  color: white;
  cursor: pointer; /* makes it clear the button is clickable */
  border: none; /* removes the border */
}

.signup-button:hover {
  background-color: #0056b3; /* darker blue on hover */
}

.signup-message {
  color: green;
  margin-top: 8px;
}
