.auth-background {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #145a9a, #478dcd);
}

.auth-container {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #414247;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.auth-title {
    margin-bottom: 20px;
    color: #145a9a;
}

.auth-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.auth-input {
    padding: 10px;
    border-radius: 4px;
    border: 2px solid #478dcd;
    outline: none;
}

.auth-button {
    background-color: #1a71c1;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 4px;
    outline: none;
    transition: background-color 0.3s ease;
}

.auth-button:hover {
    background-color: #145a9a;
    font-weight: bold;
}

.auth-toggle {
    background-color: transparent;
    color: #1a71c1;
    border: none;
    padding: 10px 20px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    outline: none;
    text-decoration: underline;
}

.auth-toggle:hover {
    font-weight: bold;
    color: #1a71c1;
}

.auth-error {
    color: #FF6347;
    margin-top: 10px;
}

.auth-input:focus {
    background-color: #eef3f8;
}

.brand-logo {
    display: block;
    margin: 0 auto 20px;
    width: 100px;
    height: auto;
}

.forgot-password-link {
    background-color: transparent;
    color: #1a71c1;
    border: none;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 4px;
    outline: none;
    text-decoration: underline;
}

.forgot-password-link:hover {
    font-weight: bold;
    color: #1a71c1;
}

/* Tablet styles */
@media screen and (max-width: 1024px) {
    .auth-container {
        max-width: 80%;
        padding: 15px;
    }

    .auth-title {
        font-size: 1.8rem;
    }

    .auth-input,
    .auth-button,
    .auth-toggle {
        font-size: 0.9rem;
    }

    .brand-logo {
        width: 80px;
    }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .auth-container {
        max-width: 90%;
        padding: 10px;
    }

    .auth-title {
        font-size: 1.5rem;
    }

    .auth-input,
    .auth-button,
    .auth-toggle {
        font-size: 0.8rem;
        padding: 8px 15px;
    }

    .brand-logo {
        width: 60px;
    }

    .forgot-password-link {
        font-size: 0.8rem;
    }
}