.leaderboard-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: var(--dashboardDark);
  border-radius: 1rem;
  padding: 1.25rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 2.5rem;
}

.leaderboard-title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaderboard-scroll-container {
  width: 100%;
  overflow-y: auto;
}

.leaderboard-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.leaderboard-icon {
  margin-right: 0.5rem;
}

.leaderboard-header,
.leaderboard-item {
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 0.75rem;
  color: white;
  transition: background-color 0.3s ease;
  border-radius: 0.5rem;
}

.leaderboard-header {
  font-weight: bold;
  background-color: var(--dashboardMidDark);
  position: sticky;
  top: 0;
  z-index: 1;
}

.leaderboard-item {
  cursor: pointer;
  margin-top: 0.5rem;
  background-color: var(--dashboardDark);
}

.leaderboard-item:hover {
  background-color: var(--dashboardLight);
}

.rank {
  font-weight: bold;
  text-align: center;
}

.user {
  text-align: left;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-pic {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-right: 1rem;
  object-fit: cover;
}

.user-details {
  display: flex;
  flex-direction: column;
}

.username {
  font-weight: bold;
}

.name {
  font-size: 0.9rem;
  color: #d4d4d4;
}

.sort-icon-container {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  vertical-align: middle;
  align-items: center;
}

.mental-health,
.productivity,
.personal-growth,
.overall {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.mental-health-score,
.productivity-score,
.personal-growth-score,
.overall-score {
  text-align: center;
}

.leaderboard-empty-message {
  color: white;
  text-align: center;
  margin-top: 1rem;
  font-size: 1.1rem;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Mobile Styles */
@media screen and (max-width: 1200px) {
  .leaderboard-container {
    max-width: 100%;
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .leaderboard-container {
    margin-top: 1rem;
    padding: 0.75rem;
  }

  .leaderboard-title {
    font-size: 1.3rem;
  }

  .leaderboard-header,
  .leaderboard-item {
    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
    font-size: 0.9rem;
    padding: 0.4rem 0;
  }

  .user-pic {
    width: 2rem;
    height: 2rem;
  }

  .username {
    font-size: 0.9rem;
  }

  .name {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 480px) {
  .leaderboard-container {
    padding: 0.5rem;
  }

  .leaderboard-title {
    font-size: 1.2rem;
  }

  .leaderboard-header,
  .leaderboard-item {
    grid-template-columns: 0.5fr 2fr 1fr;
    font-size: 0.8rem;
    padding: 0.3rem 0;
  }

  .mental-health-score,
  .productivity-score,
  .personal-growth-score {
    display: none;
  }

  .user-pic {
    width: 1.5rem;
    height: 1.5rem;
  }

  .username {
    font-size: 0.8rem;
  }

  .name {
    font-size: 0.7rem;
  }
}