.follow-popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.follow-popup-content {
  background-color: var(--dashboardDarkest);
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 80%;
  max-width: 31.25rem;
  max-height: 80vh;
  overflow-y: auto;
  color: #fff;
  font-family: Arial, sans-serif;
}

.follow-popup-close {
  float: right;
  background: none;
  border: none;
  font-size: 1.75rem;
  cursor: pointer;
  color: #d4d4d4;
  transition: color 0.3s ease;
}

.follow-popup-close:hover {
  color: #ffffff;
}

.follow-popup-content h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.follow-list {
  list-style-type: none;
  padding: 0;
}

.follow-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  padding: 0.625rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.follow-list-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.follow-list-item-left {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.follow-user-pic {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 0.75rem;
}

.follow-user-info {
  display: flex;
  flex-direction: column;
}

.follow-username {
  font-size: 1.125rem;
  color: #d4d4d4;
  font-weight: bold;
}

.follow-name {
  font-size: 1rem;
  color: #a0a0a0;
}

.follow-status {
  font-size: 1rem;
  color: #a0a0a0;
}

.follow-empty-message {
  text-align: center;
  color: #a0a0a0;
  font-size: 1.125rem;
}

.loading-message {
  text-align: center;
  color: #a0a0a0;
  padding: 0.625rem;
  font-size: 1.125rem;
}

.remove-follow-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #a0a0a0;
  padding: 0.25rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.remove-follow-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

/* Tablet styles */
@media (max-width: 48rem) {
  .follow-popup-content {
    width: 90%;
    padding: 1.25rem;
  }

  .follow-username {
    font-size: 1rem;
  }

  .follow-name {
    font-size: 0.9375rem;
  }
}

/* Phone styles */
@media (max-width: 30rem) {
  .follow-popup-content {
    width: 95%;
    padding: 1rem;
  }

  .follow-user-pic {
    width: 2.5rem;
    height: 2.5rem;
  }

  .follow-username {
    font-size: 0.9375rem;
  }

  .follow-name {
    font-size: 0.875rem;
  }
}
