.search-bar {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 20px;
    background-color: white;
    width: 92%!important;
}

.search-history-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* min-height: 32rem; */
}

.search-bar-history-sep {
  width: 80%;
  align-items: center;
  align-self: center;
  border: 1px solid;
  border-color: var(--dashboardLight);
  margin: 1.25rem;
}

.search-history-header {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  /* width: 100%; */
  overflow: hidden;
  padding-right: 1rem;
  padding-left: 1rem;
  font-size: 1.125rem;
  font-weight: bold;
}

.search-history-header>span:nth-child(2) {
  color: var(--hyperlinkBlue);
  cursor: pointer;
}

.search-history-scroll {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 1.25rem;
  align-items: center;
}

.search-history-row-item {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 85%;
  overflow: hidden;
  font-size: 1rem;
  font-weight: bold;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.search-history-row-item:hover {
  background-color: var(--dashboardMid);
}

.search-history-row-delete {
  color: var(--spaceGrayLightest);
  cursor: pointer;
  padding: 0.25rem;
}

.search-history-empty-text {
  color: var(--spaceGrayLightest);
}

.search-input {
    border: none;
    outline: none;
    flex-grow: 1;
    padding: 10px;
    font-size: 1rem;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 0;
}

.search-input:focus {
    box-shadow: 0 0 5px rgba(0, 0, 255, 0.5);
    border-color: blue;
}

.search-icon {
    color: gray;
    cursor: pointer;
    font-size: 24px;
    margin-left: 24px;
    margin-right: 24px;
}

.search-bar:hover .search-icon,
.search-input:focus + .search-icon {
    color: black;
}
