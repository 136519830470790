.cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: space-evenly;
  align-items: stretch;
  flex: 1;
  width: 100%;
}
