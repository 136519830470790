.dashboard-container {
    background-color: var(--dashboardDarkest);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    color: #fff;
    font-family: Arial, sans-serif;
    width: 100%;
    overflow: hidden;
}

.dashboard-content {
  min-height: 100vh;
  width: 100%;
  min-width: 0;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.dashboard-content > * {
  flex: 1;
  padding: 1rem;
  min-width: 0;
}


.dashboard-split {
  display: grid;
  height: 100%;
  width: 100%;
  background: var(--dashboardDarkest);
  gap: 2rem;
  align-items: flex-start;
  /* justify-content: space-evenly; */
  /* grid-template-columns: 20rem auto 18rem; */
  grid-template-columns: 20rem auto;
  overflow: hidden;
  margin-right: 2rem;
}

/* .dashboard-split-expanded-sidebar {
  display: grid;
  height: 100%;
  width: 100%;
  background: var(--dashboardDark);
  gap: 1.5rem;
  grid-template-columns: 24rem auto 20rem;
  overflow: hidden;
} */

.dashboard-side-bar-right {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    flex: 1;
    min-width: 0;
    grid-gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
    padding: 1rem;
}

@media screen and (max-width: 1200px) {
  .dashboard-split{
    grid-template-columns: 10% 50% auto;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 768px) {
  .dashboard-split{
    grid-template-columns: 1fr;
  }
}

.dashboard-user-background-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.dashboard-user-profile-container {
  width: 50%;
  /* Adjusted width */
  /* max-width: 600px; */
  /* background-color: #2a314e; */
  padding: 1.25rem;
  color: #fff;
  /* Light text for readability */
}

.dashboard-user-header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.dashboard-user-profile-pic {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  margin-right: 96px;
}

.dashboard-user-info {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 2rem;
  min-height: 8rem;
}

.dashboard-user-username {
  font-weight: bold;
  font-size: 2rem;
}

.dashboard-user-name {
  margin-top: 0.5rem;
  font-size: 1.5rem;
}

hr.dashboard-seperator {
  border-bottom: 1px solid #d4d4d4;
  margin-top: 48px;
  border-radius: 1px;
  width: 50%;
}

.dashboard-user-follow-info {
    display: flex;
    margin-top: 1rem;
}

.dashboard-user-followers,
.dashboard-user-following {
    cursor: pointer;
    margin-right: 1.5rem;
    font-size: 1rem;
    color: #d4d4d4;
    transition: color 0.3s ease;
}

.dashboard-user-followers:hover,
.dashboard-user-following:hover {
    color: #ffffff;
}

.dashboard-user-followers span,
.dashboard-user-following span {
    font-weight: bold;
    color: #ffffff;
}

.dashboard-user-followers span,
.dashboard-user-following span {
    font-weight: 900;
}

