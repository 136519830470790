.settings-background {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;
    align-content: center;
    flex: 1;
    flex-grow: 1;
    background: linear-gradient(to right, #1d243c, #2a314e);
    padding: 1.5rem;
    min-height: 100vh;
    min-width: 100vw;
}

.settings-header {
    display: flex;
    justify-content: left;
}

.settings-container {
    width: 50%;
    max-width: 600px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

    background-color: #5a5f7a;
    color: #fff;
    font-family: Arial, sans-serif; /* Optional: Define the font you prefer */
}

.profile-pic-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
}

.profile-pic {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
}

.file-input {
    position: absolute;
    width: 120px;  /* Exactly covers the profile pic */
    height: 120px;  /* Exactly covers the profile pic */
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
}

.back-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.back-icon {
    width: 48px;  /* Size of the back icon */
    height: 48px; /* Size of the back icon */
}

.settings-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    flex-grow: 1; /* Ensures the title centers ignoring the button width */
}

.settings-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.settings-error {
    margin-top: 5px; /* Reduce top margin to bring the error closer to the input */
    color: #DC143C; /* Error color */
    font-size: 14px; /* Ensure the error text is readable but not too large */
    font-weight: bold;
}

.settings-section {
    display: flex;
    justify-content: space-between; /* Ensures labels and inputs are at opposite ends */
    align-items: center;
  }

.settings-label {
    font-size: 18px;
    flex: 0 0 140px; /* Fixed width for the label */
    text-align: left; /* Align text to the left */
    margin-right: 10px; /* Space between label and input */
    /* white-space: nowrap; */
    display: flex;
    flex-grow: 1;
}

.settings-input, .toggle-container {
    flex-grow: 1; /* Input takes the remaining space */
}

.settings-input {
    width: calc(100% - 40px); /* Adjust width to make space for the icon */
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #478dcd;
    outline: none;
    font-size: 14px;
}

.password-container {
    width: calc(100% - 40px); /* Adjust width to make space for the icon */
    display: flex;
    align-items: center;
    position: relative;
}

.toggle-password-visibility {
    cursor: pointer;
    position: absolute;
    right: 10px;
    height: 24px;
    width: 24px;
}



.settings-button {
    width: 100%;
    padding: 12px;
    margin-top: 15px;
    font-size: 16px;
    border-radius: 8px;
}

.delete-button {
    width: 100%;
    padding: 12px;
    margin-top: 15px;
    font-size: 16px;
    border-radius: 8px;
}

.settings-button:hover {
    background-color: #47cd87;
}

.delete-button:hover {
  background-color: #b30000;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #47cd87;
}

input:focus + .slider {
    box-shadow: 0 0 1px #1a71c1;
}

input:checked + .slider:before {
    transform: translateX(26px);
}
