.modal-title {
  font-size: 20px;
  text-align: center;
  width: 100%;
}

.custom-modal .modal-dialog {
    position: fixed;  /* Fixed position */
    top: 50%;         /* Center vertically */
    left: 50%;        /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust the position to truly center */
    z-index: 1050;    /* Ensure it's above other content */
    width: auto;      /* Adjust width based on the content */
    max-width: 90%;   /* Max width to avoid very wide modals on large screens */
}

.custom-modal .modal-content {
    background-color: white; /* Ensure background is white */
    padding: 20px;           /* Padding inside the modal */
    border-radius: 8px;      /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25); /* Shadow for depth */
}

.modal-backdrop.show {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 1040; /* Below the modal dialog but above other content */
}


@media (max-width: 576px) {
    .custom-modal .modal-cancel-btn, .custom-modal .modal-confirm-btn {
        padding: 10px 20px; /* Slightly smaller padding on small screens */
        font-size: 16px; /* Adjust font size for small screens */
    }
}


@media (min-width: 576px) {
    .custom-modal .modal-dialog {
        max-width: 500px;
    }
}

.custom-modal .modal-header {
    padding: 15px;  /* Uniform padding for all modal components */
}

.custom-modal .modal-body {
  padding: 10px;  /* Uniform padding for all modal components */
  font-size: 18px;
  text-align: left;
  width: 100%;
}

.custom-modal .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    border-top: 1px solid #dee2e6;
}

.custom-modal .modal-cancel-btn {
    margin: 10px;
    background-color: #6c757d; /* Bootstrap's secondary color for reference */
    color: white;
    border: none;
    padding: 10px 22px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 18px;
}

.custom-modal .modal-cancel-btn:hover {
    background-color: #5a6268; /* Darker shade for hover */
}

.custom-modal .modal-confirm-btn {
    background-color: #b30000; /* Red color for danger actions */
    color: white;
    border: none;
    padding: 10px 22px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
    font-size: 18px;
}

.custom-modal .modal-confirm-btn:hover {
    background-color: #990000; /* Darker red for hover */
}

.custom-modal .modal-cancel-btn:active, .custom-modal .modal-confirm-btn:active {
    transform: translateY(1px);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.custom-modal .modal-cancel-btn:focus, .custom-modal .modal-confirm-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.75); /* Blue outline for focus */
}
