/* Base styles for FAQ page */
.faq {
    background: linear-gradient(to right, var(--primaryDark), var(--primary));
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--dashboardDarkest);
}

.faq-content {
    flex-grow: 1;
    width: 70%;
    margin: 6rem auto 4rem;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #fff;
}

.faq-item {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    transition: all 0.3s ease;
    width: 100%;
    text-align: left;
}

.faq-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-5px);
}

.faq-item h3 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: var(--dashboardLightest);
}

.faq-item h4 {
    font-size: 1.125rem;
    line-height: 1;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 1rem;
}

.faq-item p {
    font-size: 1rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 1rem;
}

.faq-item p:last-child {
    margin-bottom: 0;
}

.faq-item a {
    color: var(--dashboardLightest);
    text-decoration: none;
    transition: all 0.3s ease;
}

.faq-item a:hover {
    text-decoration: underline;
}

/* Tablet styles */
@media screen and (max-width: 1024px) {
    .content {
        width: 80%;
        padding: 2rem;
        margin: 5rem auto 3rem;
    }

    h1 {
        font-size: 2rem;
    }

    .faq-item h3 {
        font-size: 1.1rem;
    }

    .faq-item p {
        font-size: 0.9rem;
    }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .content {
        width: 90%;
        padding: 1rem;
        margin: 2rem auto 3rem;
    }

    h1 {
        font-size: 1.8rem;
    }

    .faq-item {
        padding: 1rem;
    }

    .faq-item h3 {
        font-size: 1rem;
    }

    .faq-item p {
        font-size: 0.8rem;
    }
}
