.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  background-color: #2a314e; /* Dark blue background */
}

.loading-icon {
  width: 120px; /* Adjust size as needed */
  margin-bottom: 20px; /* Space between icon and spinner */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.2); /* Light grey border for spinner */
  border-radius: 50%;
  border-top: 4px solid #fff; /* White part of the spinner */
  width: 40px; /* Spinner size */
  height: 40px; /* Spinner size */
  animation: spin 2s linear infinite; /* Animation for spinning effect */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
