.charts-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex-grow: 1;
  grid-gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.section-heading {
  white-space: nowrap;
  font-size: 1.25rem;
}

.charts-dash-summary-container {
  background-color: var(--dashboardDark);
  border-radius: 1rem;
}

.cards-row {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  flex-grow: 1;
  width: 100%!important;
  justify-content: space-between;
  align-items: stretch;
  align-self: center;
  align-content: center;
  gap: 2rem;
  padding: 0;
  margin: 0;
}

.card-chart-grouping {
  display: flex;
  flex-direction: column;
  background: var(--dashboardDark);
  border-radius: 1rem;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
}

.card-chart-grouping.overall-wellbeing {
  flex: 1;
}

.card-chart-grouping.wellbeing-indicators {
  flex: 3;
}

.chart-dash-indicators {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  width: 100%;
  background: var(--dashboardDark);
  border-radius: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (max-width: 1366px) {
  .charts-container {
    grid-gap: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
  }

  .section-heading {
    font-size: 1.1rem;
  }

  .cards-row {
    gap: 1.5rem;
  }

  .card-chart-grouping {
    padding: 0.8rem;
  }

  .chart-dash-indicators {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
}

@media (max-width: 768px) {
  .cards-row {
    flex-direction: column;
  }
}
