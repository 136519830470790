/* src/LandingPage.css */

/* Base styles (for desktop) */
.landing-page {
    background: linear-gradient(to right, var(--primaryDark), var(--primary));
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--dashboardDarkest);
}

.landing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 15%;
    background-color: transparent;
    position: absolute; /* Changed from fixed to absolute */
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    width: 70%;
    margin: 0 auto;
}

.logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logo {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.logo-text {
    font-size: 1.5rem;
    color: #fff;
    font-weight: bold;
}

.nav-button {
    background-color: transparent;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    font-weight: bold;
    font-size: 1rem;
    transition: all 0.3s ease;
    position: relative;
}

.nav-button::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: #fff;
    transition: all 0.3s ease;
}

.nav-button:hover::after {
    width: 100%;
    left: 0;
}

.nav-button:hover {
    color: var(--dashboardLightest);
}

.header-buttons {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.content {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
    width: 70%;
    margin: 6rem auto 4rem; /* Adjusted to prevent overlap with header and footer */
}

.content-left {
    flex: 1;
    text-align: left;
    padding-right: 3.25rem;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.content-left h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    color: #fff;
}

.content-right {
    flex: 1;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    color: #fff;
}

p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #fff;
}

.terms-container {
    width: 100%;
    max-width: 19rem;
    text-align: center;
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.download-button {
    background-color: #fff;
    color: var(--primaryDark);
    padding: 1rem 2rem;
    border-radius: 2rem;
    text-decoration: none;
    font-size: 1.2rem;
    transition: all 0.3s ease;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.os-icon {
    margin-right: 0.5rem;
    height: 2rem;
    width: 2rem;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.download-button:hover {
    background-color: var(--dashboardLightest);
    color: var(--primaryDark);
    border-color: var(--dashboardLightest);
}

.terms-text {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
}

.terms-link {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.8rem;
    padding: 0;
}

.terms-link:hover {
    color: #fff;
}

.demo-video {
    width: 40rem;
    height: 21.73rem;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.landing-footer {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: transparent;
    position: relative; /* Changed from fixed to relative */
    bottom: 0;
    left: 0;
    right: 0;
}

.landing-footer button {
    background-color: transparent;
    color: #fff;
    border: none;
    padding: 10px;
    margin: 0 10px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: opacity 0.3s ease;
}

.landing-footer button:hover {
    opacity: 0.7;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    max-width: 400px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.5rem;
    color: #333;
}

.popup-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
    justify-content: center;
}

.popup-logo {
    width: 40px;
    height: auto;
    margin-right: 10px;
}

.popup h2 {
    margin: 0;
    color: var(--primary);
    font-size: 1.5rem;
    line-height: 40px; /* Match the height of the logo */
}

.popup h3 {
    margin-bottom: 1rem;
    color: #555;
    font-size: 1.2rem;
}

.popup form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
}

.popup input[type="email"] {
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.popup button[type="submit"] {
    background-color: var(--primary);
    color: white;
    padding: 0.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.popup button[type="submit"]:hover {
    background-color: var(--primaryDark);
}

.popup p {
    margin-top: 1rem;
    color: #333;
    text-align: center;
}

/* Tablet styles */
@media screen and (max-width: 1024px) {
    .landing-header {
        padding: 1rem 10%;
        width: 80%;
    }

    .content {
        width: 80%;
        padding: 2rem;
        margin: 5rem auto 3rem; /* Adjusted to prevent overlap */
    }

    .content-left h1 {
        font-size: 2.5rem;
    }

    p {
        font-size: 1rem;
    }

    .download-button {
        padding: 0.75rem 2rem;
        font-size: 1rem;
    }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .landing-header {
        flex-direction: column;
        padding: 1rem 5%;
        width: 90%;
        position: relative; /* Changed to relative for mobile */
    }

    .header-buttons {
        margin-top: 1rem;
    }

    .content {
        flex-direction: column;
        width: 90%;
        padding: 1rem;
        margin: 2rem auto 3rem; /* Adjusted for mobile */
    }

    .content-left {
        padding-right: 0;
        text-align: center;
        margin-bottom: 2rem;
    }

    .content-left h1 {
        font-size: 2rem;
    }

    .buttons {
        align-items: center;
    }

    .download-button {
        padding: 0.75rem 1.5rem;
        font-size: 1rem;
    }

    .demo-video {
        width: 100%;
        height: 100%;
    }

    .landing-footer {
        flex-wrap: wrap;
        padding: 10px 0;
    }

    .landing-footer button {
        font-size: 0.8rem;
        padding: 5px;
        margin: 5px;
    }

    .popup {
        width: 95%;
        padding: 1.5rem;
        aspect-ratio: auto; /* Remove square aspect ratio for mobile */
    }
}