.background-container {
  width: 100%;
  height: 100vh;
  background-color: #1d243c; /* Dark background for the full page */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.user-profile-container{
  width: 50%; /* Adjusted width */
  /* max-width: 600px; */
  /* background-color: #2a314e; */
  padding: 20px;
  color: #fff; /* Light text for readability */
}


.private-user-fa-lock {
  height: 48px;
  width: 48px;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.private-user-header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.private-user-profile-pic {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  margin-right: 96px;
}

.private-user-text {
  flex-grow: 0; /* Allows the text to take the remaining space */
}

.private-user-info {
  display: flex;
  flex-direction: column; /* Stacks the name and username vertically */
  align-content: flex-start;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 32px;
}

.private-user-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin-top: 24px;
}

.btn-follow, .btn-requested {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  color: white;
  background-color: #007bff; /* Primary button color */
  cursor: pointer;
  /* margin-top: 10px; */
}

.btn-requested {
  background-color: #6c757d; /* Secondary button color */
}

/* Ensure the button is also aligned to the top if it's not inside another flex container */
.button-container-top { /* If you decide to wrap the button in a new div */
  display: flex;
  align-items: flex-start; /* Aligns the button to the top */
}

.button-container-bottom {
  margin-top: 12px;
}

/* Solid border */
hr.seperator {
  border-bottom: 1px solid #d4d4d4;
  margin-top: 48px;
  border-radius: 1px;
}

.fa-lock-title {
  font-weight: bold;
  color: #ffffff;
  font-size: 16px;
}

.fa-lock-subtitle {
  color: #b4b4b4;
  font-size: 14px;
}
