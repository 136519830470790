.summary-container {
  padding: 1.25rem;
  margin: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  flex: 1;
  flex-grow: 1;
  /* Standard formatting for parent containers */
  /* background-color: var(--dashboardDark); */
  /* border-radius: 1rem; */

}

.summary-action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.625rem;
}

.summary-button {
  color: #ffffff;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-right: 0.625rem;
}

.summary-button:last-child {
  margin-right: 0;
}

.summary-advice {
  font-size: 0.75rem;
  color: #888;
  margin-top: 0.625rem;
}

.error-message {
  color: red;
}

.summary-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.summary-title {
  font-size: 1.25rem;
  font-weight: bold;
}
.summary-timestamp {
  font-size: 0.8em;
  color: #999;
}