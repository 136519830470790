.dashboard-user-profile-container {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: stretch;
  flex: 1;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
}

.user-profile-background-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.private-user-container {
  width: 50%; /* Adjusted width */
  /* max-width: 600px; */
  /* background-color: #2a314e; */
  padding: 1.25rem;
  color: #fff; /* Light text for readability */
}

.user-profile-split {
  display: grid;
  height: 100%;
  width: 100%;
  background: var(--dashboardDarkest);
  gap: 2rem;
  align-items: flex-start;
  /* justify-content: space-evenly; */
  /* grid-template-columns: 20rem auto 18rem; */
  grid-template-columns: 20rem auto;
  overflow: hidden;
  margin-right: 2rem;
}


.private-user-fa-lock {
  height: 48px;
  width: 48px;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.private-user-username {
  font-weight: bold;
  font-size: 2rem;
}

.private-user-name {
  margin-top: 0.5rem;
  font-size: 1.5rem;
}

.follow-button {
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  color: white;
  background-color: var(--spaceGrayDark);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 8rem;
  min-width: 8rem;
  text-align: center;
}

.follow-button:hover {
  background-color: var(--spaceGrayMid);
}

.user-actions-dropdown {
  position: relative;
  margin-left: 0.5rem;
}

.dropdown-toggle {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: var(--spaceGrayDark);
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  z-index: 10;
}

.dropdown-menu button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  border: none;
  color: #fff;
  background-color: var(--spaceGrayDark);
  cursor: pointer;
}

.dropdown-menu button:hover {
  background-color: var(--spaceGrayMedium);
}

.follows-you-message {
  font-size: 1rem;
  color: #b4b4b4;
  margin-top: 0.5rem;
}

.private-user-header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.private-user-profile-pic {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  margin-right: 96px;
}

.private-user-text {
  flex-grow: 0; /* Allows the text to take the remaining space */
}

.private-user-info {
  display: flex;
  flex-direction: column; /* Stacks the name and username vertically */
  align-content: flex-start;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 2rem;
  min-height: 8rem;
}

.private-user-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin-top: 24px;
}

.btn-follow, .btn-requested, .btn-following {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  color: white;
  background-color: #007bff; /* Primary button color */
  cursor: pointer;
  /* margin-top: 10px; */
}

.btn-requested {
  background-color: #6c757d; /* Secondary button color */
}

.btn-following {
  background-color: #60686f; /* Secondary button color */
}

/* Ensure the button is also aligned to the top if it's not inside another flex container */
.button-container-top { /* If you decide to wrap the button in a new div */
  display: flex;
  align-items: flex-start; /* Aligns the button to the top */
}

.button-container-bottom {
  margin-top: 12px;
}

/* Solid border */
hr.seperator {
  border-bottom: 1px solid #d4d4d4;
  margin-top: 48px;
  border-radius: 1px;
}

.fa-lock-title {
  font-weight: bold;
  color: #ffffff;
  font-size: 16px;
}

.background-text {
  color: #b4b4b4;
  font-size: 14px;
}
