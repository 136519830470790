.sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 300ms ease;
  min-height: 100vh;
  flex-grow: 1;
}

.sidebar.expanded {
  width: 250px;
}

.icon {
  display: flex;
  height: 5rem;
  font-weight: bold;
  font-size: 1.5rem;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.icon > span > span {
  color: var(--primary);
}

.icon > img {
  width: 4rem;
  height: 4rem;
}

.sidebar-content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.icon-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-left: 1rem; */
  padding-bottom: 1rem;
  /* padding-right: 1rem; */
  margin-top: 2.5rem;
}

.icon-wrapper {
  margin-bottom: 1rem;
}

.menu-item-icon {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
}

.sidebar-area {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 1rem;
  width: 100%;
}

.search-area,
.notifications-area {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 2rem);
  transition: all 300ms ease;
  background: var(--dashboardDark);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 1rem;
}

.notification-count-container {
  position: relative;
}

.notifications-count {
  position: absolute;
  top: -0.35rem;
  right: -0.5rem;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 0.7rem;
  font-weight: bold;
  min-width: 0.5rem;
  min-height: 0.5rem;
  border: solid 1px var(--spaceGrayDarkest);
  z-index: 100;
  text-align: center;
  justify-content: center;
  align-content: center;
  padding: 2px 6px;
}

.notifications-header {
  font-size: 1.25rem;
  font-weight: bold;
}

.notifications-dropdown-item {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
  font-size: 1rem;
  font-weight: bold;
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
}

.notifications-history-sep {
  width: 80%;
  align-items: center;
  align-self: center;
  border: 1px solid;
  border-color: var(--dashboardLight);
  margin: 1.25rem;
}

.notifications-dropdown-empty-text {
  color: var(--spaceGrayLightest);
  align-self: center;
  align-content: center;
  justify-content: center;
  height: 100%;
}

.notifications-dropdown-item:hover {
  background-color: var(--dashboardMid);
}

.notifications-dropdown-identity {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: center;
  box-shadow: 0;
  background-color: transparent;
  border: 0;
}

.notification-dropdown-text {
  display: inline-block;
  white-space: wrap;
  color: white;
  font-size: 0.875rem;
  font-weight: bold;
}

.notifications-accept-reject-icons {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 6px;
  border: 2px solid gray;
  flex-shrink: 0;
  cursor: pointer;
  margin-left: 0.25rem;
}

.notifications-accept-reject-icons:hover:nth-child(1) {
  color: green;
}

.notifications-accept-reject-icons:hover:nth-child(2) {
  color: red;
}

.menuIcon {
  left: 5%!important;
}

.menuIcon-expanded {
  display: none;
  left: 60%!important;
}

.menu {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 3.25rem;
  margin-left: 2rem;
  position: relative;
  transition: all 300ms ease;
  border-radius: 0.75rem;
  font-size: 1rem;
  justify-content: flex-start;
  cursor: pointer;
}

.menuItem:hover {
  cursor: pointer;
}

.menu .menuItem:last-child {
  position: relative;
  margin-top: auto;
  margin-bottom: 2rem;
}

.active {
  background: var(--primaryDark);
  margin-left: 0;
}

.active::before {
  content: "";
  width: 8px;
  height: 100%;
  background: var(--primary);
  margin-right: calc(1rem - 8px);
}

.submenu {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  border-left: 0.125rem solid var(--primary);
}

/* Tablets */
@media screen and (max-width: 1200px) {
  .menuItem>span {
    display: block;
  }

  .icon {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    position: relative;
    z-index: 99;
    width: 55%;
    padding-right: 1rem;
    height: 100%;
  }

  .menuItem>span {
    display: block;
  }

  .icon {
    display: flex;
  }

  .menuIcon-expanded {
    display: flex;
    position: fixed;
    top: 1rem;
    left: 60%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    z-index: 99;
  }

  .menuIcon{
    display: flex;
    position: fixed;
    top: 1rem;
    left: 5%!important;
    padding: 0.5rem;
    border-radius: 0.5rem;
    z-index: 99;
  }

  .close{
    left: -60%;
  }

}