.insights-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: var(--dashboardDark);
  border-radius: 1rem;
  padding: 1.25rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 2.5rem;
}

.insights-icon {
  margin-right: 0.5rem;
}

.insights-title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.insights-title .insights-icon {
  display: flex;
  align-items: center;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.insights-list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.insight-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.75rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.insight-item:hover {
  background-color: var(--dashboardLight);
}

.insight-icon {
  color: white;
  margin-right: 0.5rem;
  min-width: 1.5rem;
}

.insight-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.insight-title {
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  text-align: left;
}

.insight-description {
  color: white;
  font-size: 1rem;
  text-align: left;
}

.insights-empty-message {
  color: white;
  text-align: center;
  margin-top: 1rem;
  font-size: 1.1rem;
}

/* Mobile Styles */
@media screen and (max-width: 1200px) {
  .insights-container {
    max-width: 100%;
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .insights-container {
    margin-top: 1rem;
    padding: 0.75rem;
  }

  .insights-title {
    font-size: 1.3rem;
  }

  .insight-item {
    padding: 0.4rem 0;
  }

  .insight-title, .insight-description {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .insights-container {
    padding: 0.5rem;
  }

  .insights-title {
    font-size: 1.2rem;
  }

  .insight-item {
    padding: 0.3rem 0;
  }

  .insight-title, .insight-description {
    font-size: 0.95rem;
  }

  .insight-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}
